import React from "react"
import Layout from "../components/layout"

const Error = () => {
  return (
    <Layout>
      <div className="ui-header-body-container">
        <h1 className="body-title product-title">404 Error!</h1>
      </div>
    </Layout>
  )
}

export default Error
